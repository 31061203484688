<template>
  <b-container v-if="listItems.length > 0">
    <b-row class="mb-2">
      <b-col
        cols="12"
        style="background-color: #fff;"
        class="p-0 ml-1"
      >
        <EntityList
          :fields="listFields"
          :list-items="listItems"
          :list-slug="listSlug"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";
import EntityList from "@/components/admin/EntityList.vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    EntityList
  },
  data() {
    return {
      listItems: []
    };
  },
  computed: {
    listSlug() {
      return this.$route.path.split("/").at(-1);
    },
    listFields() {
      switch (this.listSlug) {
        case "farms":
          return [
            {
              key: "name",
              label: "exploitants"
            },
            "cultures",
            {
              key: "id",
              label: "exploitants",
              class: "text-center"
            },
            {
              key: "preview",
              label: "voir l'exploitation",
              class: "text-center"
            }
          ];

        case "cooperatives":
          return [
            {
              key: "name",
              label: "coopératives"
            },
            {
              key: "id",
              label: "techniciens",
              class: "text-center"
            },
            {
              key: "preview",
              label: "voir la coopérative",
              class: "text-center"
            }
          ];

        case "industries":
          return [
            {
              key: "name",
              label: "industries"
            },
            {
              key: "id",
              label: "industriels",
              class: "text-center"
            },
            {
              key: "preview",
              label: "voir l'industrie",
              class: "text-center"
            }
          ];

        default:
          return null;
      }
    }
  },
  created() {
    this.getListItems();
  },
  methods: {
    getListItems() {
      switch (this.listSlug) {
        case "farms":
          this.$http
            .ListFarms()
            .then(({ data }) => {
              this.listItems = data.map(obj => ({
                name: obj.name,
                cultures: [
                  ...new Set([
                    // TODO get rid of this mapping when we API is ready
                    ...obj.hve_cultures.map(c => (c === "cereal" ? "ble" : c)),
                    ...obj.ges_cultures
                  ])
                ],
                id: obj.id
              }));

              this.$store.commit("admin/SET_ALLFARMS", data);
            })
            .catch(console.warn);
          break;

        case "cooperatives":
          this.$http
            .ListCooperatives()
            .then(({ data }) => {
              this.listItems = data.map(obj => ({
                name: obj.name,
                id: obj.id
              }));

              this.$store.commit("admin/SET_ALLCOOPERATIVES", data);
            })
            .catch(console.warn);
          break;

        case "industries":
          this.$http
            .ListIndustries()
            .then(({ data }) => {
              this.listItems = data.map(obj => ({
                name: obj.name,
                id: obj.id
              }));

              this.$store.commit("admin/SET_ALLINDUSTRIES", data);
            })
            .catch(console.warn);
          break;

        default:
          break;
      }
    }
  }
};
</script>
