<template>
  <b-table
    responsive
    striped
    :fields="fields"
    :items="listItems"
    class="m-0"
  >
    <template #table-colgroup="scope">
      <col
        v-for="field in scope.fields"
        :key="field.key"
        :class="{'preview-col': field.key === 'preview', 'id-col': field.key === 'id', 'cultures-col': field.key === 'cultures'}"
      >
    </template>
    <template #cell(cultures)="cultures">
      <b-badge
        v-for="culture in cultures.value"
        :key="culture"
        variant="light-primary"
        pill
        class="mr-1"
      >
        {{ localize(culture) }}
      </b-badge>
    </template>
    <template #cell(id)="id">
      <b-button
        variant="flat-secondary"
        class="btn-icon rounded-circle"
        :to="{ name: routeName, params: { id: id.value }}"
      >
        <feather-icon
          icon="UsersIcon"
          size="18"
        />
      </b-button>
    </template>
    <template #cell(preview)="data">
      <b-button
        variant="flat-secondary"
        class="btn-icon rounded-circle"
        :to="previewRouteName(data)"
      >
        <feather-icon
          icon="EyeIcon"
          size="18"
        />
      </b-button>
    </template>
  </b-table>
</template>

<script>
import { BTable, BBadge, BButton } from "bootstrap-vue";
import localize from "@/utils/localize";

export default {
  components: {
    BTable,
    BBadge,
    BButton
  },
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    listItems: {
      type: Array,
      default: () => []
    },
    listSlug: {
      type: String,
      default: ""
    }
  },
  computed: {
    routeName() {
      switch (this.listSlug) {
        case "farms":
          return "admin-farm-details";

        case "cooperatives":
          return "admin-coop-details";

        case "industries":
          return "admin-iaa-details";

        default:
          return "";
      }
    }
  },
  methods: {
    previewRouteName(data) {
      switch (this.listSlug) {
        case "farms":
          return { name: "farm-home", params: { farm_id: data.item.id } };

        case "cooperatives":
          return { name: "coop-home", params: { coop_id: data.item.id } };

        case "industries":
          return { name: "iaa-home", params: { iaa_id: data.item.id } };

        default:
          return {};
      }
    },
    localize
  }
};
</script>

<style lang="scss" scoped>
  .preview-col {
    width: 21%;
    text-align: center;
  }

  .id-col {
    width: 18%;
    text-align: center;
  }

  .cultures-col {
    width: 32%;
    text-align: center;
  }
</style>
